import { extend, localize } from "vee-validate";
import translation from "../../util/translations.js";
import {
    required as rule_required,
    doubel as rule_double,
    email as rule_email,
    min as rule_min,
    confirmed as rule_confirmed,
    regex as rule_regex,
    between as rule_between,
    alpha as rule_alpha,
    integer as rule_integer,
    digits as rule_digits,
    alpha_dash as rule_alpha_dash,
    alpha_num as rule_alpha_num,
    length as rule_length,
} from "vee-validate/dist/rules";

// eslint-disable-next-line object-curly-newline
import {
    validatorPositive,
    validatorUrlValidator,
    validatorPassword,
    validatorCreditCard,
    validatorDate,
    validatorTime,
    validatorWeek,
    validatorInternationalPhonePrefix,
    validatorElevenProof,
    validatorSofiNumber,
    validatorBTWNumber,
    validatorDocument,
    validatorAge,
    validatorDateMore,
    validatorNumberMore,
    validatorDateMoreThanToday,
    validatorCorrectPrice,
    validatorCorrectPriceConversionFactor,
    validatorCorrectNumber,
    validatorMaxValue,
    validatorDateOneMonthPast,
    validatorMultipleEmails,
    validatorCorrectPensionWeek,
    validatorMinValue,
    validatorDisableDateFuture,
    validatorDisableDatePast,
    validatorDisableTime,
    validatorBicNumber
} from "./validators";

var tranObj = new translation();

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend("required", rule_required);

export const email = extend("email", rule_email);

export const min = extend("min", rule_min);

export const confirmed = extend("confirmed", rule_confirmed);

export const regex = extend("regex", rule_regex);

export const between = extend("between", rule_between);

export const alpha = extend("alpha", rule_alpha);

export const integer = extend("integer", rule_integer);

export const digits = extend("digits", rule_digits);

export const alphaDash = extend("alpha-dash", rule_alpha_dash);

export const alphaNum = extend("alpha-num", rule_alpha_num);

export const length = extend("length", rule_length);

export const positive = extend("positive", {
    validate: validatorPositive,
    message: tranObj.trans('enter-positive-number',261),
});

export const credit = extend("credit-card", {
    validate: validatorCreditCard,
    message: tranObj.trans('invalid-credit-card',261),
});

export const password = extend("password", {
    validate: validatorPassword,
    message: tranObj.trans('password-req-error',261),
});

export const url = extend("url", {
    validate: validatorUrlValidator,
    message: tranObj.trans('invalid-url',261),
});

export const date = extend("date-check", {
    validate: validatorDate,
    message: tranObj.trans('invalid-date',261),
});

export const time = extend("timecheck", {
    validate: validatorTime,
    message: tranObj.trans('invalid-time',261),
});

export const week = extend("weekcheck", {
    validate: validatorWeek,
    message: tranObj.trans('invalid-hours-per-week',261),
});

export const age = extend("age-check", {
    validate: validatorAge,
    message: tranObj.trans('minimal-age-error',261),
});

export const dateMoreThanToday = extend("date-more-check", {
    validate: validatorDateMoreThanToday,
    message: tranObj.trans('entered-date-too-soon',261),
});

export const documentDate = extend("document-date", {
    validate: validatorDocument,
    message: tranObj.trans('expiration-date-too-far',261),
});

export const documentDateOneMonthPast = extend("document-date-month-past", {
    validate: validatorDateOneMonthPast,
    message: tranObj.trans('expiration-date-too-far',261),
});

export const phoneprefix = extend("phone-prefix", {
    validate: validatorInternationalPhonePrefix,
    message: tranObj.trans('invalid-number',261),
});

export const elevenproof = extend("eleven-proof", {
    validate: validatorElevenProof,
    message: tranObj.trans('invalid-iban',261),
});

export const correctPrice = extend("correct-Price", {
    validate: validatorCorrectPrice,
    message: tranObj.trans('invalid-number-two',261),
});

export const correctPriceConversionFactor = extend("correct-price-conversion-factor", {
    validate: validatorCorrectPriceConversionFactor,
    message: tranObj.trans('invalid-number-two',261),
});

export const correctNumber = extend("correct-Number", {
    validate: validatorCorrectNumber,
    message: tranObj.trans('invalid-number-two',261),
});

export const sofinumber = extend("sofi-number", {
    validate: validatorSofiNumber,
    message: tranObj.trans('invalid-bsn',261),
});

export const btwnumber = extend("btw-number", {
    validate: validatorBTWNumber,
    message: tranObj.trans('invalid-btw',261),
});

export const decimal_max_value = extend("app-max-value", {
    validate: validatorMaxValue,
    message: (field, args) => tranObj.trans('maximum-value',237)+ ' ' + args[0].replace( '.', ',')
});

export const multipleEmails = extend("multiple-emails", {
    validate: validatorMultipleEmails,
    message: tranObj.trans('invalid-email',261),
});

export const correctPensionCount = extend("correct-pension-count", {
    validate: validatorCorrectPensionWeek,
    message: tranObj.trans('pdw-pension-count-between',234),
});

const paramNames = ['dateTo'];

export const dateMoreThan = extend("date-more-than", {
    validate: validatorDateMore,
    message: tranObj.trans('incorrect-date',261),
},{
    paramNames
});

export const numberMoreThan = extend("number-more-than", {
    validate: validatorNumberMore,
    message: tranObj.trans('incorrect-week',261),
},{
});


export const decimal_min_value = extend("app-min-value", {
    validate: validatorMinValue,
    message: (field, args) => tranObj.trans('minimal-value',261) + args[0]
});


export const date_block_past = extend("disable-date-past", {
    validate: validatorDisableDatePast,
    message: tranObj.trans('end-date-past-error',261)
});

export const date_block = extend("disable-date-future", {
    validate: validatorDisableDateFuture,
    message: (field, args) => (args[0] == 365 ? tranObj.trans('date-more-than-year-error',261) : tranObj.trans('cannot-select-blocked-date',261))
});

export const time_block = extend("disable-time", {
    validate: validatorDisableTime,
    message: tranObj.trans('blocked-for-hour',261)
});

export const bic_number = extend('bic-number', {
    validate: validatorBicNumber,
    message: tranObj.trans('invalid-bic',261)
});


